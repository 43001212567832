import {notify} from '@components/user/notifications';
import useTreatment from '@hooks/useTreatment';
import {CustomProgramAgendaVM} from '@models/serverModels';
import {getCustomProgramContentUrlRq} from '@store/apiEndpoints/customProgram/queries';
import {simpleQueryFn, simpleMutationFn} from '@store/queryClient';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {useState} from 'react';
import {useQuery, useMutation} from 'react-query';
import AgendaCard from './AgendaCard';
import {getAddCustomProgramDateToCalendarRm} from '@store/apiEndpoints/customProgram/mutations';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useCustomProgramAgendaQuery} from '@hooks/apiEndpoints/customProgram/queries';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IAgendaCardContainerProps {
  agenda: CustomProgramAgendaVM;
  selectedCardId: number | undefined;
  handleCardSelected: (id: number | undefined) => void;
  customProgramId: number;
  isActivePlanItem: boolean;
}

function AgendaCardContainer({
  agenda,
  handleCardSelected,
  selectedCardId,
  customProgramId,
  isActivePlanItem,
}: IAgendaCardContainerProps) {
  const [enableFetchAttachmentUrl, setEnableFetchAttachmentUrl] =
    useState(false);
  const getUserQuery = useGetUserQuery();
  const {refetch: refetchAgenda} = useCustomProgramAgendaQuery(
    customProgramId,
    {enabled: false}
  );
  // Get File Attachments

  const handleClickFileAttachment = async () => {
    setEnableFetchAttachmentUrl(true);
  };
  const customProgramContentUrlRq = getCustomProgramContentUrlRq(
    agenda.attachments?.[0]?.id
  );
  const addCustomProgramDateToCalendarMutation = useMutation(
    (contentId: number) => {
      const updateCustomProgramContentRm =
        getAddCustomProgramDateToCalendarRm(contentId);
      return simpleMutationFn<number>(
        updateCustomProgramContentRm.path,
        updateCustomProgramContentRm.payload
      );
    },
    {
      onSuccess: async () => {
        notify.addCustomProgramDateSuccess();
      },
      onError: () => {
        notify.addCustomProgramDateError();
      },
      onSettled: () => refetchAgenda(),
    }
  );
  const addDateToCalendar = () => {
    addCustomProgramDateToCalendarMutation.mutateAsync(agenda.id);
  };

  const isConnectCalendarOn =
    useTreatment(FeatureFlagExperiments.ConnectCalendar) ===
    FeatureFlagTreatments.On;

  const isExternalCalendarConnected =
    getUserQuery.data?.connectedCalendar !== null;

  useQuery<string>(
    customProgramContentUrlRq.queryKey,
    () => simpleQueryFn(customProgramContentUrlRq.path),
    {
      enabled: enableFetchAttachmentUrl,
      onSuccess: (url) => {
        if (!!url) {
          window.open(url, '_blank').focus();
          setEnableFetchAttachmentUrl(false);
        }
      },
      onError: () => {
        notify.attachmentOpenedError();
      },
    }
  );

  return (
    <AgendaCard
      isActivePlanItem={isActivePlanItem}
      customProgramId={customProgramId}
      selectedCardId={selectedCardId}
      onCardSelected={handleCardSelected}
      onAddDateToCalendar={addDateToCalendar}
      handleClickFileAttachment={handleClickFileAttachment}
      agenda={agenda}
      isConnectCalendarOn={isConnectCalendarOn}
      isExternalCalendarConnected={isExternalCalendarConnected}
    />
  );
}

export default AgendaCardContainer;
