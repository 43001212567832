import {useRef, useEffect, useCallback} from 'react';

export const usePolling = (
  id: string,
  options: {
    onPending: () => void;
    onComplete: () => void;
    onError: () => void;
    interval?: number;
    poll: (id: string) => Promise<'completed' | 'error' | 'pending'>;
    autoStart?: boolean;
  }
) => {
  const timeoutId = useRef<NodeJS.Timeout>();
  const isPolling = useRef(false);

  const stopPolling = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
    isPolling.current = false;
  }, []);

  const poll = useCallback(async () => {
    if (!isPolling.current) return;

    try {
      const status = await options.poll(id);

      if (status === 'completed') {
        stopPolling();
        options.onComplete();
      } else if (status === 'error') {
        stopPolling();
        options.onError();
      } else {
        options.onPending();
        // Schedule next poll
        timeoutId.current = setTimeout(poll, options.interval || 10000);
      }
    } catch (error) {
      stopPolling();
      options.onError();
    }
  }, [id, options, stopPolling]);

  const startPolling = useCallback(() => {
    if (isPolling.current) return;

    isPolling.current = true;
    poll();
  }, []);

  useEffect(() => {
    if (options.autoStart) {
      startPolling();
    }
    return () => stopPolling();
  }, [startPolling, stopPolling, options.autoStart]);

  return {startPolling, stopPolling};
};
