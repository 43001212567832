// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademySkillRatingVM.cs

import {z} from 'zod';

export const AcademySkillRatingVMSchema = z.object({
  id: z.number(),
  lxpScaleId: z.number().nullable().optional(),
  lxpSkillRatingId: z.number().nullable().optional(),
  rating: z.number(),
  scale: z.number(),
  skillTagId: z.number(),
});
