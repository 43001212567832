import {useState} from 'react';
import {UseMutationResult, useMutation, useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {
  addStaleTime,
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import queryClient from '@store/queryClient';
import {getProgramDetailsRq} from '@store/apiEndpoints/program/queries';
import {
  getUpdateAcademyContentStatusRm,
  getUpdateAcademyMarketplaceProgramCompletionStatus,
} from '@store/apiEndpoints/academy/mutations';
import {
  getCustomStepContentLinksRq,
  getAcademyOverviewRq,
} from '@store/apiEndpoints/academy/queries';
import StepCard from './StepCard';
import {
  CustomContentLink,
  ProgramDetailsVM,
  UserPlanAcademyContentStatusUpdatePayload,
  UserPlanProgramItemVM,
  UserPlanTemplate,
} from '@models/serverModels';
import {notify} from '@components/user/notifications';
import {
  AcademyContentStatus,
  PlanItemType,
  UserProgramStatus,
} from '@generated/enums';
import {formatUrls} from '@utils/stringUtils';
import {useDeleteFromPlanById} from '@hooks/apiEndpoints/userPlan/mutations';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {AcademyStepVM, UserItemStatusVM} from '@generated/interfaces';
import {
  AcademyStepType,
  DisplayStatus,
  DisplayStatusWarningLevel,
} from '@generated/enums';
import {isNil} from 'lodash';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {
  useAcademyLevelsQueryCached,
  useAcademyStepContentAttachmentsQueryCached,
} from '@generated/hooks/academy.get.hooks';

type StepCardContainerProps = {
  academyId: number | undefined;
  cardCount: number;
  index: number;
  isActivePlanItem: boolean;
  levelId: number;
  plan: UserPlanProgramItemVM[];
  shouldBeInFocus: boolean;
  step: AcademyStepVM;
};

function StepCardContainer({
  academyId,
  cardCount,
  index,
  isActivePlanItem,
  levelId,
  plan,
  shouldBeInFocus,
  step,
}: StepCardContainerProps) {
  const params = useParams();
  const [_selected, setSelected] = useState(false);
  const [_approvalRequested, setApprovalRequested] = useState(false);

  const {invalidateExact: invalidateAcademyLevels} =
    useAcademyLevelsQueryCached(
      {
        academyId,
        queryParams: {excludeUserProgress: false},
      },
      {enabled: false}
    );

  // Feature flags
  // Check with Taylor on when this code can be removed. See LI-5906 for more details
  const {isFeatureFlagOn} = useFeatureFlags();
  const isCredSparkDemoEnabled = isFeatureFlagOn.CredsparkDemo;

  const defaultCompletionStatus =
    step.status.displayStatus === DisplayStatus.Completed;
  const [isCompleted, setCompletedStatus] = useState(defaultCompletionStatus);

  // Queries to refresh card data
  const updateCardState = (data: any) => {
    setSelected(data?.selected);
    setApprovalRequested(data?.approvalRequested);
  };

  // Custom Content Links
  const customStepContentLinksRq = getCustomStepContentLinksRq(step.id);
  const customStepContentLinksQuery = useQuery<CustomContentLink[]>(
    customStepContentLinksRq.queryKey,
    () => simpleQueryFn(customStepContentLinksRq.path),
    {
      placeholderData: () => [],
      staleTime: addStaleTime(),
    }
  );

  // Get attachments for the step
  const {data: stepAttachments} = useAcademyStepContentAttachmentsQueryCached(
    {
      academyStepId: step?.id,
    },
    {
      placeholderData: () => [],
      enabled: !!step?.attachments?.length,
      onError: () => {
        notify.attachmentOpenedError();
      },
    }
  );

  // Program Details
  const programDetailsRq =
    step.type === AcademyStepType.MarketplaceProgram
      ? getProgramDetailsRq(step.programId)
      : {path: '', queryKey: []};

  const getProgramDetailsQuery = useQuery<ProgramDetailsVM>(
    programDetailsRq.queryKey,
    () => simpleQueryFn(programDetailsRq.path),
    {
      enabled: step.type === AcademyStepType.MarketplaceProgram,
      onSuccess: updateCardState,
      refetchOnWindowFocus: false,
    }
  );

  // Mark Step Content As Complete
  const addCustomProgramSectionMutation = useMutation(
    (programContent: UserPlanAcademyContentStatusUpdatePayload) => {
      const addCustomProgramSectionRm =
        getUpdateAcademyContentStatusRm(programContent);
      return simpleMutationFn<number>(
        addCustomProgramSectionRm.path,
        addCustomProgramSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        await simpleInvalidateExactQueryFn(
          getAcademyOverviewRq(parseInt(params.academyId)).queryKey
        );
      },
      onError: async () => {
        setCompletedStatus(defaultCompletionStatus);
        notify.updateAcademyStepCompletionError();
      },
    }
  );
  /*
   * If you're wondering why we're conflating `DisplayStatus` and
   * `AcademyContentStatus` it is because as of right now the
   * server is expecting the old `AcademyContentStatus` values when
   * we make updates, but is sending us `DisplayStatus` values when
   * we make get requests, so we need to differentiate when it
   * comes to what we're reading and what we're sending to the server.
   * */
  const invertCompletionStatus = (
    stepStatus: DisplayStatus
  ): AcademyContentStatus => {
    return stepStatus === DisplayStatus.Completed
      ? AcademyContentStatus.Planned
      : AcademyContentStatus.Completed;
  };

  const updateMarketplaceProgramStatus = async (
    contentId: number,
    status: UserProgramStatus
  ) => {
    await updateAcademyMarketplaceProgramCompletionStatus.mutateAsync(
      {
        contentId,
        status,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
        },
      }
    );
  };

  const updateAcademyContentStatus = useMutation(
    (academyContent: UserPlanAcademyContentStatusUpdatePayload) => {
      const updateAcademyContentStatusRm =
        getUpdateAcademyContentStatusRm(academyContent);
      return simpleMutationFn<UserPlanTemplate>(
        updateAcademyContentStatusRm.path,
        updateAcademyContentStatusRm.payload
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
      onError: async () => {
        notify.updateAcademyStepCompletionError();
      },
    }
  );

  const updateMarketplaceProgramCompletion = async (
    isMarkingAsComplete: boolean
  ) => {
    const programStatus = isMarkingAsComplete
      ? UserProgramStatus.Complete
      : UserProgramStatus.Planned;
    await updateMarketplaceProgramStatus(step.id, programStatus);
  };

  const updateCustomProgramCompletion = async (status: UserItemStatusVM) => {
    setCompletedStatus(step.status.displayStatus !== DisplayStatus.Completed);
    await addCustomProgramSectionMutation.mutateAsync(
      {
        contentId: step.id,
        status: invertCompletionStatus(status.displayStatus),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
        },
      }
    );
  };

  const updateAcademyMarketplaceProgramCompletionStatus = useMutation(
    ({contentId, status}: {contentId: number; status: UserProgramStatus}) => {
      const updateProgramProgressRm =
        getUpdateAcademyMarketplaceProgramCompletionStatus({
          contentId,
          status,
        });
      return simpleMutationFn<UserPlanTemplate>(
        updateProgramProgressRm.path,
        updateProgramProgressRm.payload
      );
    },
    {
      onError: async () => {
        notify.updateAcademyStepCompletionError();
      },
    }
  );

  const refreshAcademyLevels = () => {
    if (!!academyId) {
      invalidateAcademyLevels(); // generated api hook used in v2
    }
  };

  const handleMarketplaceProgramCompleted = async () => {
    if (!isActivePlanItem) return;
    await updateMarketplaceProgramCompletion(true);
    refreshAcademyLevels();
  };

  const handleCustomProgramCompleted = async () => {
    if (!isActivePlanItem) return;
    await updateCustomProgramCompletion({
      displayStatus: step.status.displayStatus,
      displayStatusWarningLevel: DisplayStatusWarningLevel.Default,
      isDone: null,
    });
    refreshAcademyLevels();
  };

  const handleUnmarkAsCompleted = async () => {
    if (!isActivePlanItem) return;
    await updateMarketplaceProgramCompletion(false);
    refreshAcademyLevels();
  };

  const handleDeletePlanItem = async (
    deleteFromPlanById: UseMutationResult
  ) => {
    if (!isActivePlanItem) return;
    await deleteFromPlanById.mutateAsync({
      itemId: step.programId,
      itemType: PlanItemType.Program,
    });
    refreshAcademyLevels();
  };

  const {user} = useAuth();
  const exchangeRate = useExchangeRate(
    !isNil(step?.licenseCurrency) ? step?.licenseCurrency : step?.currency,
    user?.currency
  );

  const handleSkipProgram = async (contentId: number) => {
    if (!isActivePlanItem) return;

    await updateAcademyContentStatus.mutateAsync({
      contentId,
      status: AcademyContentStatus.Skipped,
    });
    refreshAcademyLevels();
  };

  const handleUnskipProgram = async (contentId: number) => {
    if (!isActivePlanItem) return;

    await updateAcademyContentStatus.mutateAsync({
      contentId,
      status: AcademyContentStatus.Planned,
    });
    refreshAcademyLevels();
  };

  const formattedDescription = formatUrls(step.description);
  const deleteFromPlanById = useDeleteFromPlanById({});
  return (
    <StepCard
      academyId={academyId}
      attachments={stepAttachments}
      cardCount={cardCount}
      credSparkDemoEnabled={isCredSparkDemoEnabled}
      deletePlanItem={() => handleDeletePlanItem(deleteFromPlanById)}
      exchangeRate={exchangeRate}
      formattedDescription={formattedDescription}
      index={index}
      isActivePlanItem={isActivePlanItem}
      levelId={levelId}
      links={customStepContentLinksQuery?.data}
      markCustomProgramAsComplete={handleCustomProgramCompleted}
      markMarketplaceProgramAsComplete={handleMarketplaceProgramCompleted}
      plan={plan}
      program={getProgramDetailsQuery?.data}
      shouldBeInFocus={shouldBeInFocus}
      skipProgram={handleSkipProgram}
      step={step}
      unmarkAsCompleted={handleUnmarkAsCompleted}
      unskipProgram={handleUnskipProgram}
      usersCurrency={user?.currency}
      onProgramStatusChanged={getProgramDetailsQuery.refetch}
      isCompleted={isCompleted}
    />
  );
}

export default StepCardContainer;
