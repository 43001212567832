import {AcademyStepType, ContentType} from '@generated/enums';

export const mapContentTypeToAcademyStepType = (
  type: ContentType
): AcademyStepType => {
  switch (type) {
    case ContentType.Text:
      return AcademyStepType.TextContent;
    case ContentType.Project:
      return AcademyStepType.ProjectContent;
    case ContentType.Event:
      return AcademyStepType.EventContent;
    case ContentType.Discussion:
      return AcademyStepType.Discussion;
    case ContentType.SkillRating:
      return AcademyStepType.LxpSkillRating;
    default:
      return null;
  }
};
