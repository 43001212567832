import {
  isLocal,
  isLocalDB,
} from '@utils/environment-helpers';
import { UserManagerSettings } from 'oidc-client-ts';

class UrlConfig {
  readonly env: string;
  readonly WEB_URL: string;
  readonly API_URL: string;
  readonly DIRECT_API_URL: string;
  readonly IDENTITY_URL: string;
  readonly AUTH_CONFIG: UserManagerSettings;

  constructor() {
    const apiBasePath = '/develop/api';
    const webBase = `https://${location.host}/develop`;

    const localApiBase = 'http://localhost:5200/';
    const localWebBase = 'https://localhost:5100/';
    const localIdentityBase = isLocalDB
      ? 'https://localhost:5001'
      : 'https://staging.degreed.com/develop/auth';

    if (isLocal) {
      this.API_URL = localApiBase;
      this.DIRECT_API_URL = localApiBase;
      this.WEB_URL = localWebBase;
      this.AUTH_CONFIG = {
        authority: localIdentityBase,
        client_id: 'learnin-react',
        redirect_uri: localWebBase + 'signin-callback.html',
        silent_redirect_uri: localWebBase + 'silent-refresh.html',
        post_logout_redirect_uri: localWebBase,
        automaticSilentRenew: true,
        response_type: 'code',
        scope: 'openid offline_access',
      };
      return;
    }

    this.API_URL = apiBasePath;
    this.DIRECT_API_URL = apiBasePath;
    this.WEB_URL = webBase;
    this.AUTH_CONFIG = {
      authority: webBase + '/auth',
      client_id: 'learnin-react',
      redirect_uri: webBase + '/signin-callback.html',
      silent_redirect_uri: webBase + '/silent-refresh.html',
      post_logout_redirect_uri: webBase,
      automaticSilentRenew: true,
      response_type: 'code',
      scope: 'openid offline_access',
    };
  }
}

export default UrlConfig;
