import {useState} from 'react';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {
  getProjectSubmissionAttachmentUrlRq,
  getCustomProgramContentUrlRq,
} from '@store/apiEndpoints/customProgram/queries';
import {CustomizableLearningCategory} from '@generated/enums';
import {
  getCustomStepContentAttachmentUrlRq,
  getAcademyProjectSubmissionAttachmentUrlRq,
} from '@store/apiEndpoints/academy/queries';
import {isValidUrl} from '@utils/urlUtils';
import {notify} from '@components/user/notifications';

const useOpenUpload = (
  customizableLearningCategory: CustomizableLearningCategory
) => {
  const [submissionUploadId, setSubmissionUploadId] = useState<
    undefined | number
  >();
  const isAcademyUpload =
    customizableLearningCategory === CustomizableLearningCategory.Academy;
  const isCustomProgramUpload =
    customizableLearningCategory === CustomizableLearningCategory.CustomProgram;

  const academyProjectSubmissionAttachmentUrlRq =
    getAcademyProjectSubmissionAttachmentUrlRq(submissionUploadId);
  useQuery<string>(
    academyProjectSubmissionAttachmentUrlRq.queryKey,
    () => simpleQueryFn(academyProjectSubmissionAttachmentUrlRq.path),
    {
      enabled: !!submissionUploadId && isAcademyUpload,
      onSuccess: (url) => {
        if (!!url && isAcademyUpload) {
          if (isValidUrl(url)) {
            window.open(url, '_blank').focus();
          } else {
            notify.attachmentOpenedError();
          }
          setSubmissionUploadId(undefined);
        }
      },
    }
  );

  const customProgramProjectSubmissionAttachmentUrlRq =
    getProjectSubmissionAttachmentUrlRq(submissionUploadId);
  useQuery<string>(
    customProgramProjectSubmissionAttachmentUrlRq.queryKey,
    () => simpleQueryFn(customProgramProjectSubmissionAttachmentUrlRq.path),
    {
      enabled: !!submissionUploadId && isCustomProgramUpload,
      onSuccess: (url) => {
        if (!!url && isCustomProgramUpload) {
          if (isValidUrl(url)) {
            window.open(url, '_blank').focus();
          } else {
            notify.attachmentOpenedError();
          }
          setSubmissionUploadId(undefined);
        }
      },
    }
  );

  return setSubmissionUploadId;
};

export default useOpenUpload;

/*
|--------------------------------------------------------------------------
| 
|--------------------------------------------------------------------------
*/

export const useOpenAttachmentUpload = (
  customizableLearningCategory: CustomizableLearningCategory
) => {
  const [submissionUploadId, setSubmissionUploadId] = useState<
    undefined | number
  >();

  const isAcademyUpload =
    customizableLearningCategory === CustomizableLearningCategory.Academy;
  const isCustomProgramUpload =
    customizableLearningCategory === CustomizableLearningCategory.CustomProgram;

  const academyProjectSubmissionAttachmentUrlRq =
    getCustomStepContentAttachmentUrlRq(submissionUploadId);
  useQuery<string>(
    academyProjectSubmissionAttachmentUrlRq.queryKey,
    () => simpleQueryFn(academyProjectSubmissionAttachmentUrlRq.path),
    {
      enabled: !!submissionUploadId && isAcademyUpload,
      onSuccess: (url) => {
        if (!!url && isAcademyUpload) {
          if (isValidUrl(url)) {
            window.open(url, '_blank').focus();
          } else {
            notify.attachmentOpenedError();
          }
          setSubmissionUploadId(undefined);
        }
      },
    }
  );

  const customProgramProjectSubmissionAttachmentUrlRq =
    getCustomProgramContentUrlRq(submissionUploadId);
  useQuery<string>(
    customProgramProjectSubmissionAttachmentUrlRq.queryKey,
    () => simpleQueryFn(customProgramProjectSubmissionAttachmentUrlRq.path),
    {
      enabled: !!submissionUploadId && isCustomProgramUpload,
      onSuccess: (url) => {
        if (!!url && isCustomProgramUpload) {
          if (isValidUrl(url)) {
            window.open(url, '_blank').focus();
          } else {
            notify.attachmentOpenedError();
          }
          setSubmissionUploadId(undefined);
        }
      },
    }
  );

  return setSubmissionUploadId;
};
