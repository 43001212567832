// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Dev/Requests/ResyncExternalUserCompletionsRequest.cs

import {z} from 'zod';

export const ResyncExternalUserCompletionsRequestSchema = z.object({
  academyId: z.number(),
  batchSize: z.number(),
  companyId: z.number(),
  lxpContentIds: z.array(z.number()).nullable().optional(),
  userLxpIds: z.array(z.string()).nullable().optional(),
});
