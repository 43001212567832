import {
  AcademyBrandPayload,
  AcademyLevelPayload,
  AcademyMoveStepPayload,
  AcademyOrderedItemPayload,
  AcademyPayload,
  AcademyProgramStatusUpdate,
  AcademyProgramStepPayload,
  AcademySingleUserPayload,
  AcademyStepContentPayload,
  AcademyStepContentUpdatePayload,
  AcademyUserPayload,
  AcademyUserPermissionPayload,
  AcademyVM,
  AddLxpContentToAcademyRequest,
  AddUsersToAcademyPayload,
  ContentFeedbackVM,
  CreateAcademyLevelRequest,
  CreateAcademyStepMessageRequest,
  NewFeedbackPayload,
  UpdateAcademyStepRequirementPayload,
  UserPermissionUpdatePayload,
  UserPlanAcademyContentStatusUpdatePayload,
  UserPlanAcademyStepContentVM,
  UserPlanContentLxpSkillRating,
  UserPlanContentRsvpUpdate,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {AcademyPayloadSchema} from '../zodSchemas/AcademyPayloadSchema.schema';
import {AcademyUserPayloadSchema} from '../zodSchemas/AcademyUserPayloadSchema.schema';
import {AcademySingleUserPayloadSchema} from '../zodSchemas/AcademySingleUserPayloadSchema.schema';
import {CreateAcademyLevelRequestSchema} from '../zodSchemas/CreateAcademyLevelRequestSchema.schema';
import {AcademyProgramStepPayloadSchema} from '../zodSchemas/AcademyProgramStepPayloadSchema.schema';
import {AcademyStepContentPayloadSchema} from '../zodSchemas/AcademyStepContentPayloadSchema.schema';
import {AddUsersToAcademyPayloadSchema} from '../zodSchemas/AddUsersToAcademyPayloadSchema.schema';
import {CreateAcademyStepMessageRequestSchema} from '../zodSchemas/CreateAcademyStepMessageRequestSchema.schema';
import {AcademyLevelPayloadSchema} from '../zodSchemas/AcademyLevelPayloadSchema.schema';
import {AcademyOrderedItemPayloadSchema} from '../zodSchemas/AcademyOrderedItemPayloadSchema.schema';
import {UpdateAcademyStepRequirementPayloadSchema} from '../zodSchemas/UpdateAcademyStepRequirementPayloadSchema.schema';
import {AcademyStepContentUpdatePayloadSchema} from '../zodSchemas/AcademyStepContentUpdatePayloadSchema.schema';
import {AcademyMoveStepPayloadSchema} from '../zodSchemas/AcademyMoveStepPayloadSchema.schema';
import {UserPlanAcademyContentStatusUpdatePayloadSchema} from '../zodSchemas/UserPlanAcademyContentStatusUpdatePayloadSchema.schema';
import {UserPlanContentRsvpUpdateSchema} from '../zodSchemas/UserPlanContentRsvpUpdateSchema.schema';
import {AcademyBrandPayloadSchema} from '../zodSchemas/AcademyBrandPayloadSchema.schema';
import {AcademyProgramStatusUpdateSchema} from '../zodSchemas/AcademyProgramStatusUpdateSchema.schema';
import {NewFeedbackPayloadSchema} from '../zodSchemas/NewFeedbackPayloadSchema.schema';
import {AcademyUserPermissionPayloadSchema} from '../zodSchemas/AcademyUserPermissionPayloadSchema.schema';
import {UserPermissionUpdatePayloadSchema} from '../zodSchemas/UserPermissionUpdatePayloadSchema.schema';
import {AddLxpContentToAcademyRequestSchema} from '../zodSchemas/AddLxpContentToAcademyRequestSchema.schema';
import {UserPlanContentLxpSkillRatingSchema} from '../zodSchemas/UserPlanContentLxpSkillRatingSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/academy-levels/{academyLevelId}/academy-steps/{academyStepId}/discussions/{discussionId}/messages 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation} from '@generated/hooks';
 *
 *  const academiesAcademyLevelsAcademyStepsDiscussionsMessagesPost = useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesAcademyLevelsAcademyStepsDiscussionsMessagesPost.mutate({
 *    payload: {
 *      ...CreateAcademyStepMessageRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      academyLevelId,
 *      academyStepId,
 *      discussionId
 *    }
 *  })
 *
 */

export const useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation =
  mutationHookFactory<
    CreateAcademyStepMessageRequest, // payload
    {
      academyId: number | string;
      academyLevelId: number | string;
      academyStepId: number | string;
      discussionId: string;
    }, // path variables
    null
  >(
    'useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPostMutation',
    'post',
    ({academyId, academyLevelId, academyStepId, discussionId}) =>
      `academies/${academyId}/academy-levels/${academyLevelId}/academy-steps/${academyStepId}/discussions/${discussionId}/messages`,
    {payloadSchema: CreateAcademyStepMessageRequestSchema}
  );

/*
|--------------------------------------------------------------------------
|  add-date-to-my-calendar/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAddDateToMyCalendarMutation} from '@generated/hooks';
 *
 *  const academiesAddDateToMyCalendar = useAcademiesAddDateToMyCalendarMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesAddDateToMyCalendar.mutate({
 *    pathVars: {
 *      academyStepId
 *    }
 *  })
 *
 */

export const useAcademiesAddDateToMyCalendarMutation = mutationHookFactory<
  null,
  {academyStepId: number | string}, // path variables
  number // return type
>(
  'useAcademiesAddDateToMyCalendarMutation',
  'post',
  ({academyStepId}) => `academies/add-date-to-my-calendar/${academyStepId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  add-user-permissions 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAddUserPermissionsMutation} from '@generated/hooks';
 *
 *  const academiesAddUserPermissions = useAcademiesAddUserPermissionsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesAddUserPermissions.mutate({
 *    payload: {
 *      ...AcademyUserPermissionPayload
 *    },
 *  })
 *
 */

export const useAcademiesAddUserPermissionsMutation = mutationHookFactory<
  AcademyUserPermissionPayload, // payload
  null,
  null
>(
  'useAcademiesAddUserPermissionsMutation',
  'post',
  () => `academies/add-user-permissions`,
  {payloadSchema: AcademyUserPermissionPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  {academyId}/levels/{academyLevelId}/lxpContent 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesLevelsLxpContentMutation} from '@generated/hooks';
 *
 *  const academiesLevelsLxpContent = useAcademiesLevelsLxpContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesLevelsLxpContent.mutate({
 *    payload: {
 *      ...AddLxpContentToAcademyRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      academyLevelId
 *    }
 *  })
 *
 */

export const useAcademiesLevelsLxpContentMutation = mutationHookFactory<
  AddLxpContentToAcademyRequest, // payload
  {academyId: number; academyLevelId: number}, // path variables
  null
>(
  'useAcademiesLevelsLxpContentMutation',
  'post',
  ({academyId, academyLevelId}) =>
    `academies/${academyId}/levels/${academyLevelId}/lxpContent`,
  {payloadSchema: AddLxpContentToAcademyRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  {academyId}/levels/{academyLevelId}/lxpskillrating 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesLevelsLxpskillratingMutation} from '@generated/hooks';
 *
 *  const academiesLevelsLxpskillrating = useAcademiesLevelsLxpskillratingMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesLevelsLxpskillrating.mutate({
 *    payload: {
 *      ...UserPlanContentLxpSkillRating
 *    },
 *    pathVars: {
 *      academyId,
 *      academyLevelId
 *    }
 *  })
 *
 */

export const useAcademiesLevelsLxpskillratingMutation = mutationHookFactory<
  UserPlanContentLxpSkillRating, // payload
  {academyId: number | string; academyLevelId: number | string}, // path variables
  null
>(
  'useAcademiesLevelsLxpskillratingMutation',
  'post',
  ({academyId, academyLevelId}) =>
    `academies/${academyId}/levels/${academyLevelId}/lxpskillrating`,
  {payloadSchema: UserPlanContentLxpSkillRatingSchema}
);

/*
|--------------------------------------------------------------------------
|  submit-content-feedback 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesSubmitContentFeedbackMutation} from '@generated/hooks';
 *
 *  const academiesSubmitContentFeedback = useAcademiesSubmitContentFeedbackMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesSubmitContentFeedback.mutate({
 *    payload: {
 *      ...NewFeedbackPayload
 *    },
 *  })
 *
 */

export const useAcademiesSubmitContentFeedbackMutation = mutationHookFactory<
  NewFeedbackPayload, // payload
  null,
  ContentFeedbackVM[] // return type
>(
  'useAcademiesSubmitContentFeedbackMutation',
  'post',
  () => `academies/submit-content-feedback`,
  {payloadSchema: NewFeedbackPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-user-permission 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUpdateUserPermissionMutation} from '@generated/hooks';
 *
 *  const academiesUpdateUserPermission = useAcademiesUpdateUserPermissionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesUpdateUserPermission.mutate({
 *    payload: {
 *      ...UserPermissionUpdatePayload
 *    },
 *  })
 *
 */

export const useAcademiesUpdateUserPermissionMutation = mutationHookFactory<
  UserPermissionUpdatePayload, // payload
  null,
  null
>(
  'useAcademiesUpdateUserPermissionMutation',
  'post',
  () => `academies/update-user-permission`,
  {payloadSchema: UserPermissionUpdatePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  upload-content-attachment/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUploadContentAttachmentMutation} from '@generated/hooks';
 *
 *  const academiesUploadContentAttachment = useAcademiesUploadContentAttachmentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesUploadContentAttachment.mutate({
 *    pathVars: {
 *      academyStepId
 *    }
 *  })
 *
 */

export const useAcademiesUploadContentAttachmentMutation = mutationHookFactory<
  null,
  {academyStepId: number}, // path variables
  number // return type
>(
  'useAcademiesUploadContentAttachmentMutation',
  'post',
  ({academyStepId}) => `academies/upload-content-attachment/${academyStepId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  add-academy 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAcademyMutation} from '@generated/hooks';
 *
 *  const addAcademy = useAddAcademyMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAcademy.mutate({
 *    payload: {
 *      ...AcademyPayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAddAcademyMutation = mutationHookFactory<
  AcademyPayload, // payload
  null,
  number // return type
>('useAddAcademyMutation', 'post', () => `academies/add-academy`, {
  payloadSchema: AcademyPayloadSchema,
});

/*
|--------------------------------------------------------------------------
|  add-academy-content-step 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAcademyContentStepMutation} from '@generated/hooks';
 *
 *  const addAcademyContentStep = useAddAcademyContentStepMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAcademyContentStep.mutate({
 *    payload: {
 *      ...AcademyStepContentPayload
 *    },
 *  })
 *
 */

export const useAddAcademyContentStepMutation = mutationHookFactory<
  AcademyStepContentPayload, // payload
  null,
  number // return type
>(
  'useAddAcademyContentStepMutation',
  'post',
  () => `academies/add-academy-content-step`,
  {payloadSchema: AcademyStepContentPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  add-academy-level 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAcademyLevelMutation} from '@generated/hooks';
 *
 *  const addAcademyLevel = useAddAcademyLevelMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAcademyLevel.mutate({
 *    payload: {
 *      ...CreateAcademyLevelRequest
 *    },
 *  })
 *
 */

export const useAddAcademyLevelMutation = mutationHookFactory<
  CreateAcademyLevelRequest, // payload
  null,
  number // return type
>('useAddAcademyLevelMutation', 'post', () => `academies/add-academy-level`, {
  payloadSchema: CreateAcademyLevelRequestSchema,
});

/*
|--------------------------------------------------------------------------
|  add-academy-program-steps 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAcademyProgramStepsMutation} from '@generated/hooks';
 *
 *  const addAcademyProgramSteps = useAddAcademyProgramStepsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAcademyProgramSteps.mutate({
 *    payload: {
 *      ...AcademyProgramStepPayload
 *    },
 *  })
 *
 */

export const useAddAcademyProgramStepsMutation = mutationHookFactory<
  AcademyProgramStepPayload, // payload
  null,
  null
>(
  'useAddAcademyProgramStepsMutation',
  'post',
  () => `academies/add-academy-program-steps`,
  {payloadSchema: AcademyProgramStepPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  add-academy-user 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAcademyUserMutation} from '@generated/hooks';
 *
 *  const addAcademyUser = useAddAcademyUserMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAcademyUser.mutate({
 *    payload: {
 *      ...AcademySingleUserPayload
 *    },
 *  })
 *
 */

export const useAddAcademyUserMutation = mutationHookFactory<
  AcademySingleUserPayload, // payload
  null,
  null
>('useAddAcademyUserMutation', 'post', () => `academies/add-academy-user`, {
  payloadSchema: AcademySingleUserPayloadSchema,
});

/*
|--------------------------------------------------------------------------
|  add-academy-users 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAcademyUsersMutation} from '@generated/hooks';
 *
 *  const addAcademyUsers = useAddAcademyUsersMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAcademyUsers.mutate({
 *    payload: {
 *      ...AcademyUserPayload
 *    },
 *  })
 *
 */

export const useAddAcademyUsersMutation = mutationHookFactory<
  AcademyUserPayload, // payload
  null,
  boolean // return type
>('useAddAcademyUsersMutation', 'post', () => `academies/add-academy-users`, {
  payloadSchema: AcademyUserPayloadSchema,
});

/*
|--------------------------------------------------------------------------
|  add-users-to-academy 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddUsersToAcademyMutation} from '@generated/hooks';
 *
 *  const addUsersToAcademy = useAddUsersToAcademyMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addUsersToAcademy.mutate({
 *    payload: {
 *      ...AddUsersToAcademyPayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAddUsersToAcademyMutation = mutationHookFactory<
  AddUsersToAcademyPayload, // payload
  null,
  null
>(
  'useAddUsersToAcademyMutation',
  'post',
  () => `academies/add-users-to-academy`,
  {payloadSchema: AddUsersToAcademyPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  duplicate-academy/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDuplicateAcademyMutation} from '@generated/hooks';
 *
 *  const duplicateAcademy = useDuplicateAcademyMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  duplicateAcademy.mutate({
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useDuplicateAcademyMutation = mutationHookFactory<
  null,
  {academyId: number | string}, // path variables
  number // return type
>(
  'useDuplicateAcademyMutation',
  'post',
  ({academyId}) => `academies/duplicate-academy/${academyId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  duplicate-academy-step-content/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDuplicateAcademyStepContentMutation} from '@generated/hooks';
 *
 *  const duplicateAcademyStepContent = useDuplicateAcademyStepContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  duplicateAcademyStepContent.mutate({
 *    pathVars: {
 *      contentId
 *    }
 *  })
 *
 */

export const useDuplicateAcademyStepContentMutation = mutationHookFactory<
  null,
  {contentId: number | string}, // path variables
  number // return type
>(
  'useDuplicateAcademyStepContentMutation',
  'post',
  ({contentId}) => `academies/duplicate-academy-step-content/${contentId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  update-academy/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyMutation} from '@generated/hooks';
 *
 *  const updateAcademy = useUpdateAcademyMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademy.mutate({
 *    payload: {
 *      ...AcademyPayload
 *    },
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 */

export const useUpdateAcademyMutation = mutationHookFactory<
  AcademyPayload, // payload
  {academyId: number | string}, // path variables
  null
>(
  'useUpdateAcademyMutation',
  'post',
  ({academyId}) => `academies/update-academy/${academyId}`,
  {payloadSchema: AcademyPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-branding 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyBrandingMutation} from '@generated/hooks';
 *
 *  const updateAcademyBranding = useUpdateAcademyBrandingMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyBranding.mutate({
 *    payload: {
 *      ...AcademyBrandPayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyBrandingMutation = mutationHookFactory<
  AcademyBrandPayload, // payload
  null,
  null
>(
  'useUpdateAcademyBrandingMutation',
  'post',
  () => `academies/update-academy-branding`,
  {payloadSchema: AcademyBrandPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-content-step 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyContentStepMutation} from '@generated/hooks';
 *
 *  const updateAcademyContentStep = useUpdateAcademyContentStepMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyContentStep.mutate({
 *    payload: {
 *      ...AcademyStepContentUpdatePayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyContentStepMutation = mutationHookFactory<
  AcademyStepContentUpdatePayload, // payload
  null,
  null
>(
  'useUpdateAcademyContentStepMutation',
  'post',
  () => `academies/update-academy-content-step`,
  {payloadSchema: AcademyStepContentUpdatePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-level 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyLevelMutation} from '@generated/hooks';
 *
 *  const updateAcademyLevel = useUpdateAcademyLevelMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyLevel.mutate({
 *    payload: {
 *      ...AcademyLevelPayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyLevelMutation = mutationHookFactory<
  AcademyLevelPayload, // payload
  null,
  null
>(
  'useUpdateAcademyLevelMutation',
  'post',
  () => `academies/update-academy-level`,
  {payloadSchema: AcademyLevelPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-level-order 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyLevelOrderMutation} from '@generated/hooks';
 *
 *  const updateAcademyLevelOrder = useUpdateAcademyLevelOrderMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyLevelOrder.mutate({
 *    payload: {
 *      ...AcademyOrderedItemPayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyLevelOrderMutation = mutationHookFactory<
  AcademyOrderedItemPayload, // payload
  null,
  null
>(
  'useUpdateAcademyLevelOrderMutation',
  'post',
  () => `academies/update-academy-level-order`,
  {payloadSchema: AcademyOrderedItemPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-marketplace-program-completion-status 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyMarketplaceProgramCompletionStatusMutation} from '@generated/hooks';
 *
 *  const updateAcademyMarketplaceProgramCompletionStatus = useUpdateAcademyMarketplaceProgramCompletionStatusMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyMarketplaceProgramCompletionStatus.mutate({
 *    payload: {
 *      ...AcademyProgramStatusUpdate
 *    },
 *  })
 *
 */

export const useUpdateAcademyMarketplaceProgramCompletionStatusMutation =
  mutationHookFactory<
    AcademyProgramStatusUpdate, // payload
    null,
    boolean // return type
  >(
    'useUpdateAcademyMarketplaceProgramCompletionStatusMutation',
    'post',
    () => `academies/update-academy-marketplace-program-completion-status`,
    {payloadSchema: AcademyProgramStatusUpdateSchema}
  );

/*
|--------------------------------------------------------------------------
|  update-academy-step-content-rsvp 
|--------------------------------------------------------------------------
*/

/**
 *
 *  @obsolete Use update-academy-step-content-status instead
 *
 *  @example
 *
 *  import {useUpdateAcademyStepContentRsvpMutation} from '@generated/hooks';
 *
 *  const updateAcademyStepContentRsvp = useUpdateAcademyStepContentRsvpMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyStepContentRsvp.mutate({
 *    payload: {
 *      ...UserPlanContentRsvpUpdate
 *    },
 *  })
 *
 *
 *  @obsolete Use update-academy-step-content-status instead
 */

export const useUpdateAcademyStepContentRsvpMutation = mutationHookFactory<
  UserPlanContentRsvpUpdate, // payload
  null,
  UserPlanAcademyStepContentVM // return type
>(
  'useUpdateAcademyStepContentRsvpMutation',
  'post',
  () => `academies/update-academy-step-content-rsvp`,
  {payloadSchema: UserPlanContentRsvpUpdateSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-step-content-status 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyStepContentStatusMutation} from '@generated/hooks';
 *
 *  const updateAcademyStepContentStatus = useUpdateAcademyStepContentStatusMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyStepContentStatus.mutate({
 *    payload: {
 *      ...UserPlanAcademyContentStatusUpdatePayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyStepContentStatusMutation = mutationHookFactory<
  UserPlanAcademyContentStatusUpdatePayload, // payload
  null,
  UserPlanAcademyStepContentVM // return type
>(
  'useUpdateAcademyStepContentStatusMutation',
  'post',
  () => `academies/update-academy-step-content-status`,
  {payloadSchema: UserPlanAcademyContentStatusUpdatePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-step-order 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyStepOrderMutation} from '@generated/hooks';
 *
 *  const updateAcademyStepOrder = useUpdateAcademyStepOrderMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyStepOrder.mutate({
 *    payload: {
 *      ...AcademyOrderedItemPayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyStepOrderMutation = mutationHookFactory<
  AcademyOrderedItemPayload, // payload
  null,
  AcademyVM // return type
>(
  'useUpdateAcademyStepOrderMutation',
  'post',
  () => `academies/update-academy-step-order`,
  {payloadSchema: AcademyOrderedItemPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-step-requirement 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyStepRequirementMutation} from '@generated/hooks';
 *
 *  const updateAcademyStepRequirement = useUpdateAcademyStepRequirementMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyStepRequirement.mutate({
 *    payload: {
 *      ...UpdateAcademyStepRequirementPayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyStepRequirementMutation = mutationHookFactory<
  UpdateAcademyStepRequirementPayload, // payload
  null,
  null
>(
  'useUpdateAcademyStepRequirementMutation',
  'post',
  () => `academies/update-academy-step-requirement`,
  {payloadSchema: UpdateAcademyStepRequirementPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-academy-steps-level 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateAcademyStepsLevelMutation} from '@generated/hooks';
 *
 *  const updateAcademyStepsLevel = useUpdateAcademyStepsLevelMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateAcademyStepsLevel.mutate({
 *    payload: {
 *      ...AcademyMoveStepPayload
 *    },
 *  })
 *
 */

export const useUpdateAcademyStepsLevelMutation = mutationHookFactory<
  AcademyMoveStepPayload, // payload
  null,
  AcademyVM // return type
>(
  'useUpdateAcademyStepsLevelMutation',
  'post',
  () => `academies/update-academy-steps-level`,
  {payloadSchema: AcademyMoveStepPayloadSchema}
);
