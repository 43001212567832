import {useState} from 'react';
import {
  checkAddMemberStatus,
  getMemberIds,
  getUserAndGroupOptions,
} from './utils';
import {
  useAcademiesMembersMutation,
  useAcademiesUsersAllAdminQuery,
} from '@generated/hooks';
import {TablePaginationConfig} from 'antd';
import {notify} from '@components/user/notifications';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {CompanyMembersForAdding, LoadOptions, TableParams} from './types';
import {useParams} from 'react-router-dom';
import {simpleDeleteFn} from '@store/queryClient';
import {useMutation} from 'react-query';
import {getRemoveAcademiesUserRm} from '@store/apiEndpoints/academy/mutations';
import {ManageAddedMembersTable} from './ManageAddedMembersTable';
import {usePolling} from '@hooks/usePolling';

interface Props {
  restrictedAccess: boolean;
}

export const ManageAddedMembers = ({restrictedAccess}: Props) => {
  const params = useParams();
  const academyId = params.academyId;
  const {user} = useAuth();
  const PAGE_SIZE = 10;

  // State variables
  const [inputValue, setInputValue] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pageSize: PAGE_SIZE,
    pageNumber: 1,
    sortBy: 'name',
    isAscending: true,
  });
  const [selectedMembesForAdding, setSelectedMembesForAdding] = useState<
    CompanyMembersForAdding[]
  >([]);
  const {startPolling} = usePolling(academyId, {
    onPending: () => {
      setIsUpdating(true);
    },
    onComplete: () => {
      invalidateAcademyMembers();
      setIsUpdating(false);
    },
    onError: () => {
      setIsUpdating(false);
      notify.academyUserAddedError();
    },
    interval: 10000,
    poll: checkAddMemberStatus,
    autoStart: restrictedAccess,
  });

  // Queries
  const mutation = useAcademiesMembersMutation();

  const {data: academyMembers, invalidateExact: invalidateAcademyMembers} =
    useAcademiesUsersAllAdminQuery({
      academyId: parseInt(academyId),
      queryParams: {...tableParams},
    });

  const loadOptions: LoadOptions = async (inputValue, callback) => {
    try {
      const options = await getUserAndGroupOptions(
        inputValue,
        academyMembers.results,
        user?.companyId,
        true
      );
      callback(options);
    } catch (error) {
      callback([]);
    }
  };

  // Handle Remove User
  const removeUserAcademyAccessMutation = useMutation(
    (userId: number) => {
      const removeAcademyUsersMutation = getRemoveAcademiesUserRm(
        parseInt(params.academyId),
        userId
      );
      return simpleDeleteFn<null>(
        removeAcademyUsersMutation.path,
        removeAcademyUsersMutation.payload
      );
    },
    {
      onSuccess: async () => {
        await invalidateAcademyMembers();
      },
    }
  );
  const handleRemoveUser = (userId: number) => {
    removeUserAcademyAccessMutation.mutateAsync(userId);
  };

  // Handle Add Members to local state
  const onCompanyMemberSearchChange = (
    selectedOptions: CompanyMembersForAdding[]
  ) => {
    setSelectedMembesForAdding(selectedOptions || []);
  };

  // Add Members to Academy
  const handleClickAddMembers = async () => {
    try {
      setIsUpdating(true);
      const {userCompanyIds, groupIds} = getMemberIds(selectedMembesForAdding);
      await mutation.mutateAsync({
        payload: {userCompanyIds, groupIds},
        pathVars: {academyId: parseInt(academyId)},
      });
      setSelectedMembesForAdding([]);
      startPolling();
    } catch (error) {
      setIsUpdating(false);
      notify.academyUserAddedError();
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters,
    sorter
  ) => {
    setTableParams({
      pageSize: pagination.pageSize,
      pageNumber: pagination.current,
      sortBy: sorter.field,
      isAscending: sorter.order === 'ascend',
    });
  };

  return (
    <>
      {restrictedAccess && (
        <ManageAddedMembersTable
          academyMembers={academyMembers}
          selectedMembesForAdding={selectedMembesForAdding}
          loadOptions={loadOptions}
          inputValue={inputValue}
          onCompanyMemberSearchChange={onCompanyMemberSearchChange}
          handleClickAddMembers={handleClickAddMembers}
          handleTableChange={handleTableChange}
          handleRemoveUser={handleRemoveUser}
          tableParams={tableParams}
          setInputValue={setInputValue}
          isUpdating={isUpdating}
        />
      )}
    </>
  );
};
