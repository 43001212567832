import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';

import {
  ImageContainer,
  InfoContainer,
  LoadingStateContainer,
  MainContainer,
  SkillChip,
  SkillsContainer,
  SkillsTitle,
  Summary,
  SummaryAndSkillsContainer,
  TitleContainer,
  NonLinkTitleContainer,
} from './CatalogContentModal.styled';
import {i18n, k} from '@i18n/translate';
import {truncate} from 'lodash';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {useByTypeQuery} from '@generated/hooks/lxpContent.get.hooks';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';

interface CatalogContentModalProps {
  visible: boolean;
  onCancel: () => void;
  inputId: string | number;
  inputType: string;
  title?: string;
}

function CatalogContentModal({
  inputId,
  inputType,
  onCancel,
  visible,
  title,
}: CatalogContentModalProps) {
  const {data, isFetching, isLoading} = useByTypeQuery({
    id: inputId,
    type: inputType,
  });
  const modalTitle = data?.title || title || '';
  return (
    <LearnInModal
      width={567}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      onCancel={onCancel}
      displayFooterContent={'none'}
      ariaLabel={modalTitle}>
      <MainContainer>
        {isLoading || isFetching ? (
          <LoadingStateContainer>
            <LoadingSpinner />
          </LoadingStateContainer>
        ) : (
          <>
            <ImageContainer>
              <img
                src={data?.imageUrl || getPlaceholderImgSrc(data?.id)}
                alt=""
                onError={({currentTarget}) => {
                  currentTarget.onerror = null;
                  currentTarget.src = getPlaceholderImgSrc(data?.id);
                }}
              />
            </ImageContainer>
            <InfoContainer>
              {data?.format && <span>{data?.format}</span>}
              <span>
                {(data?.durationValue || '') +
                  ' ' +
                  (data?.durationUnitType || '')}
              </span>
              {/* <span>Format</span> */}
            </InfoContainer>
            {data?.link ? (
              <TitleContainer
                aria-label={`${data?.title}`}
                href={data?.link || ''}
                target="_blank">
                {data?.title || title || i18n.t(k.GENERIC__NOT_FOUND)}
              </TitleContainer>
            ) : (
              <NonLinkTitleContainer
                aria-label={`${data?.title || title || i18n.t(k.GENERIC__NOT_FOUND)}`}>
                {data?.title || title || i18n.t(k.GENERIC__NOT_FOUND)}
              </NonLinkTitleContainer>
            )}
            <SummaryAndSkillsContainer>
              <Summary>{data?.description}</Summary>
              {!!data?.skills?.length && (
                <SkillsContainer>
                  <SkillsTitle>{i18n.t(k.GENERIC__SKILL__PLURAL)}</SkillsTitle>
                  {data?.skills?.map((skill) => {
                    return (
                      <SkillChip key={skill}>
                        {skill?.length > 22 ? (
                          <LearnInTooltip title={skill}>
                            {truncate(skill, {length: 22})}
                          </LearnInTooltip>
                        ) : (
                          truncate(skill, {length: 22})
                        )}
                      </SkillChip>
                    );
                  })}
                </SkillsContainer>
              )}
            </SummaryAndSkillsContainer>
          </>
        )}
      </MainContainer>
    </LearnInModal>
  );
}

export default CatalogContentModal;
