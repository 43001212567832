import {
  AcademyStepRequirementProperty,
  AcademyStepType,
  CurrencyCode,
  LxpContentType,
  RsvpStatus,
  SubmissionType,
} from './../enums';
import {AcademyStepVM} from './../interfaces';
import {
  AcademySkillRatingVMMockData,
  getAcademySkillRatingVMMockData,
} from './AcademySkillRatingVM.mock';
import {
  AttachmentVMMockData,
  getAttachmentVMMockData,
} from './AttachmentVM.mock';
import {
  ContentFeedbackVMMockData,
  getContentFeedbackVMMockData,
} from './ContentFeedbackVM.mock';
import {
  CustomProgramCohortSearchVMMockData,
  getCustomProgramCohortSearchVMMockData,
} from './CustomProgramCohortSearchVM.mock';
import {
  IAcademyStepBasicInfoMockData,
  getIAcademyStepBasicInfoMockData,
} from './IAcademyStepBasicInfo.mock';
import {LinkVMMockData, getLinkVMMockData} from './LinkVM.mock';
import {MessageDtoMockData, getMessageDtoMockData} from './MessageDto.mock';
import {
  UserItemStatusVMMockData,
  getUserItemStatusVMMockData,
} from './UserItemStatusVM.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyStepVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyStepVMMockData(
  args?: Partial<AcademyStepVM>
): AcademyStepVM {
  return {
    ...getIAcademyStepBasicInfoMockData(),
    academyLevelId: faker.datatype.number(),
    allowPeerVisibility: faker.datatype.boolean(),
    allowSkip: faker.datatype.boolean(),
    attachmentGuids: [faker.word.noun()], // optional
    attachments: [getAttachmentVMMockData()], // optional
    cohortDisplayed: getCustomProgramCohortSearchVMMockData(), // optional
    completedPercent: faker.datatype.number(), // optional
    completionDate: String(faker.date.past()), // optional
    cost: faker.datatype.number(), // optional
    currency: CurrencyCode.USD, // optional
    description: faker.lorem.lines(), // optional
    descriptionAttachmentGuids: [faker.lorem.lines()], // optional
    discussionId: String(faker.datatype.number()), // optional
    dueDateTimeZone: faker.address.timeZone(), // optional
    dueDateUtc: String(faker.date.past()), // optional
    duration: faker.word.noun(), // optional
    endDateUtc: String(faker.date.past()), // optional
    eventLink: faker.internet.url(), // optional
    eventTimeZone: faker.address.timeZone(), // optional
    feedback: [getContentFeedbackVMMockData()], // optional
    googleCalendarLink: faker.internet.url(), // optional
    id: faker.datatype.number(),
    isApproved: faker.datatype.boolean(), // optional
    isCompletable: faker.datatype.boolean(),
    isEventConnectedToCalendar: faker.datatype.boolean(),
    isFutureCohort: faker.datatype.boolean(),
    isSubmissionTypeEditable: faker.datatype.boolean(), // optional
    licenseCost: faker.datatype.number(), // optional
    licenseCurrency: CurrencyCode.USD, // optional
    licenseId: faker.datatype.number(), // optional
    links: [getLinkVMMockData()], // optional
    lxpContentType: LxpContentType.Unknown,
    lxpDurationUnitType: faker.word.noun(), // optional
    lxpDurationValue: faker.datatype.number(),
    messageCount: faker.datatype.number(), // optional
    messagesPreview: [getMessageDtoMockData()], // optional
    order: faker.datatype.number(),
    outlookCalendarLink: faker.internet.url(), // optional
    peerSubmissionCount: faker.datatype.number(), // optional
    planItemId: faker.datatype.number(), // optional
    programId: faker.datatype.number(), // optional
    programType: faker.word.noun(), // optional
    projectContentCompletedOn: String(faker.date.past()), // optional
    projectSubmissionGuids: [faker.word.noun()],
    providerDescription: faker.lorem.lines(), // optional
    providerLogoUrl: faker.internet.url(), // optional
    providerName: faker.name.fullName(), // optional
    requiredId: faker.datatype.number(), // optional
    requirementProperty: AcademyStepRequirementProperty.Completion, // optional
    rsvpStatus: RsvpStatus.None, // optional
    showToUser: faker.datatype.boolean(),
    skillRatings: [getAcademySkillRatingVMMockData()],
    smallImageUrl: faker.image.imageUrl(), // optional
    startDateUtc: String(faker.date.past()), // optional
    status: getUserItemStatusVMMockData(),
    submissionType: SubmissionType.Text, // optional
    submittedFiles: [getAttachmentVMMockData()], // optional
    submittedLinks: [getLinkVMMockData()], // optional
    submittedResponse: faker.word.noun(), // optional
    title: faker.company.catchPhrase(),
    type: AcademyStepType.MarketplaceProgram,
    userCompanyId: faker.datatype.number(),
    userMessages: [getMessageDtoMockData()], // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyStepVMMockData: AcademyStepVM = {
  ...IAcademyStepBasicInfoMockData,
  academyLevelId: 1,
  allowPeerVisibility: true,
  allowSkip: true,
  attachmentGuids: ['string'], // optional
  attachments: [AttachmentVMMockData], // optional
  cohortDisplayed: CustomProgramCohortSearchVMMockData, // optional
  completedPercent: 123, // optional
  completionDate: '2023-03-29T22:17:19.738072', // optional
  cost: 123, // optional
  currency: CurrencyCode.USD, // optional
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', // optional
  descriptionAttachmentGuids: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  ], // optional
  discussionId: '1', // optional
  dueDateTimeZone: 'Asia/Dubai', // optional
  dueDateUtc: '2023-03-29T22:17:19.738072', // optional
  duration: 'string', // optional
  endDateUtc: '2023-03-29T22:17:19.738072', // optional
  eventLink: 'https://this-person-does-not-exist.com/en', // optional
  eventTimeZone: 'Asia/Dubai', // optional
  feedback: [ContentFeedbackVMMockData], // optional
  googleCalendarLink: 'https://this-person-does-not-exist.com/en', // optional
  id: 1,
  isApproved: true, // optional
  isCompletable: true,
  isEventConnectedToCalendar: true,
  isFutureCohort: true,
  isSubmissionTypeEditable: true, // optional
  licenseCost: 123, // optional
  licenseCurrency: CurrencyCode.USD, // optional
  licenseId: 1, // optional
  links: [LinkVMMockData], // optional
  lxpContentType: LxpContentType.Unknown,
  lxpDurationUnitType: 'string', // optional
  lxpDurationValue: 123,
  messageCount: 5, // optional
  messagesPreview: [MessageDtoMockData], // optional
  order: 123,
  outlookCalendarLink: 'https://this-person-does-not-exist.com/en', // optional
  peerSubmissionCount: 5, // optional
  planItemId: 1, // optional
  programId: 1, // optional
  programType: 'string', // optional
  projectContentCompletedOn: '2023-03-29T22:17:19.738072', // optional
  projectSubmissionGuids: ['string'],
  providerDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', // optional
  providerLogoUrl: 'https://this-person-does-not-exist.com/en', // optional
  providerName: 'Bobby Johnson', // optional
  requiredId: 1, // optional
  requirementProperty: AcademyStepRequirementProperty.Completion, // optional
  rsvpStatus: RsvpStatus.None, // optional
  showToUser: true,
  skillRatings: [AcademySkillRatingVMMockData],
  smallImageUrl: 'https://loremflickr.com/640/480', // optional
  startDateUtc: '2023-03-29T22:17:19.738072', // optional
  status: UserItemStatusVMMockData,
  submissionType: SubmissionType.Text, // optional
  submittedFiles: [AttachmentVMMockData], // optional
  submittedLinks: [LinkVMMockData], // optional
  submittedResponse: 'string', // optional
  title: 'Advanced Web Design',
  type: AcademyStepType.MarketplaceProgram,
  userCompanyId: 1,
  userMessages: [MessageDtoMockData], // optional
};
