// Do not modify directly
// Generated from file: learnin.core.api/ViewModels/Academies/AcademyStepVM.cs

import {z} from 'zod';
import {IAcademyStepBasicInfoSchema} from './IAcademyStepBasicInfoSchema.schema';
import {AttachmentVMSchema} from './AttachmentVMSchema.schema';
import {CustomProgramCohortSearchVMSchema} from './CustomProgramCohortSearchVMSchema.schema';
import {CurrencyCodeSchema} from './CurrencyCodeSchema.schema';
import {ContentFeedbackVMSchema} from './ContentFeedbackVMSchema.schema';
import {LinkVMSchema} from './LinkVMSchema.schema';
import {LxpContentTypeSchema} from './LxpContentTypeSchema.schema';
import {MessageDtoSchema} from './MessageDtoSchema.schema';
import {AcademyStepRequirementPropertySchema} from './AcademyStepRequirementPropertySchema.schema';
import {RsvpStatusSchema} from './RsvpStatusSchema.schema';
import {AcademySkillRatingVMSchema} from './AcademySkillRatingVMSchema.schema';
import {UserItemStatusVMSchema} from './UserItemStatusVMSchema.schema';
import {SubmissionTypeSchema} from './SubmissionTypeSchema.schema';
import {AcademyStepTypeSchema} from './AcademyStepTypeSchema.schema';

const schema = z.object({
  academyLevelId: z.number(),
  allowPeerVisibility: z.boolean(),
  allowSkip: z.boolean(),
  attachmentGuids: z.array(z.string()).nullable().optional(),
  attachments: z.array(AttachmentVMSchema).nullable().optional(),
  cohortDisplayed: CustomProgramCohortSearchVMSchema.nullable().optional(),
  completedPercent: z.number().nullable().optional(),
  completionDate: z.string().nullable().optional(),
  cost: z.number().nullable().optional(),
  currency: CurrencyCodeSchema.nullable().optional(),
  description: z.string().nullable().optional(),
  descriptionAttachmentGuids: z.array(z.string()).nullable().optional(),
  discussionId: z.string().nullable().optional(),
  dueDateTimeZone: z.string().nullable().optional(),
  dueDateUtc: z.string().nullable().optional(),
  duration: z.string().nullable().optional(),
  endDateUtc: z.string().nullable().optional(),
  eventLink: z.string().nullable().optional(),
  eventTimeZone: z.string().nullable().optional(),
  feedback: z.array(ContentFeedbackVMSchema).nullable().optional(),
  googleCalendarLink: z.string().nullable().optional(),
  id: z.number(),
  isApproved: z.boolean().nullable().optional(),
  isCompletable: z.boolean(),
  isEventConnectedToCalendar: z.boolean(),
  isFutureCohort: z.boolean(),
  isSubmissionTypeEditable: z.boolean().nullable().optional(),
  licenseCost: z.number().nullable().optional(),
  licenseCurrency: CurrencyCodeSchema.nullable().optional(),
  licenseId: z.number().nullable().optional(),
  links: z.array(LinkVMSchema).nullable().optional(),
  lxpContentType: LxpContentTypeSchema,
  lxpDurationUnitType: z.string().nullable().optional(),
  lxpDurationValue: z.number(),
  messageCount: z.number().nullable().optional(),
  messagesPreview: z.array(MessageDtoSchema).nullable().optional(),
  order: z.number(),
  outlookCalendarLink: z.string().nullable().optional(),
  peerSubmissionCount: z.number().nullable().optional(),
  planItemId: z.number().nullable().optional(),
  programId: z.number().nullable().optional(),
  programType: z.string().nullable().optional(),
  projectContentCompletedOn: z.string().nullable().optional(),
  projectSubmissionGuids: z.array(z.string()),
  providerDescription: z.string().nullable().optional(),
  providerLogoUrl: z.string().nullable().optional(),
  providerName: z.string().nullable().optional(),
  requiredId: z.number().nullable().optional(),
  requirementProperty:
    AcademyStepRequirementPropertySchema.nullable().optional(),
  rsvpStatus: RsvpStatusSchema.nullable().optional(),
  showToUser: z.boolean(),
  skillRatings: z.array(AcademySkillRatingVMSchema),
  smallImageUrl: z.string().nullable().optional(),
  startDateUtc: z.string().nullable().optional(),
  status: UserItemStatusVMSchema,
  submissionType: SubmissionTypeSchema.nullable().optional(),
  submittedFiles: z.array(AttachmentVMSchema).nullable().optional(),
  submittedLinks: z.array(LinkVMSchema).nullable().optional(),
  submittedResponse: z.string().nullable().optional(),
  title: z.string(),
  type: AcademyStepTypeSchema,
  userCompanyId: z.number(),
  userMessages: z.array(MessageDtoSchema).nullable().optional(),
});

export const AcademyStepVMSchema = IAcademyStepBasicInfoSchema.merge(schema);
