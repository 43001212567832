import {AcademySkillRatingVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademySkillRatingVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademySkillRatingVMMockData(
  args?: Partial<AcademySkillRatingVM>
): AcademySkillRatingVM {
  return {
    id: faker.datatype.number(),
    lxpScaleId: faker.datatype.number(), // optional
    lxpSkillRatingId: faker.datatype.number(), // optional
    rating: faker.datatype.number(),
    scale: faker.datatype.number(),
    skillTagId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademySkillRatingVMMockData: AcademySkillRatingVM = {
  id: 1,
  lxpScaleId: 1, // optional
  lxpSkillRatingId: 1, // optional
  rating: 123,
  scale: 123,
  skillTagId: 1,
};
