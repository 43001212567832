/** Get a parameter from a URL string */
export const getUrlParameter = (href: string, param: string): string =>
  new URL(href).searchParams.get(param)?.split('/')[0]; // removes trailing slash

/** Remove the specified search parameter from a URL, and replace its history state */
export const removeParameter = (href: string, param: string) => {
  const url = new URL(href);
  if (url.searchParams.has(param)) {
    url.searchParams.delete(param);
    window.history.replaceState(window.history.state, '', url.toString());
  }
};

/** Leverages the browser's URL parser to return a boolean for if the
 * provided href string is identified as a valid URL or not */
export const isValidUrl = (href: string): boolean => {
  try {
    new URL(href);
    return true;
  } catch (err) {
    console.log(`Invalid URL: ${href}`);
    return false;
  }
};
