import Grid from '@blocks/Grid';
import React from 'react';
import Attachments from './Attachments';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  AllowSkipCheckbox,
  Description,
  DueDate,
  PeerVisibilityCheckbox,
  SubmissionType,
  Title,
} from './Form';
import {getInitialDueDateAndTime} from './Form/utils';
import {FormProps} from './AddEditContent.config';

const ProjectForm: React.FC<FormProps> = ({
  step,
  attachmentProps,
  showRichTextUploads,
  canShowAllowSkip,
  stepRequirementComponent,
}) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isPeerVisibilityEnabled = isFeatureFlagOn.PeerVisibility;

  const initialValues = getInitialDueDateAndTime(step);

  return (
    <Grid.Container>
      <Grid.Box>
        <Title initialValue={step?.title} />
      </Grid.Box>

      <Grid.Box>
        <Description
          initialValue={step?.description}
          richTextUploads={showRichTextUploads}
        />
      </Grid.Box>

      <DueDate
        initialDueDate={initialValues?.initialDueDate}
        initialDueDateTime={initialValues?.initialDueDateTime}
        initialTimeZoneId={step?.dueDateTimeZone}
      />

      {stepRequirementComponent}

      {canShowAllowSkip && (
        <Grid.Box full style={{marginBottom: 12}}>
          <AllowSkipCheckbox initialValue={step?.allowSkip} />
        </Grid.Box>
      )}

      <Grid.Box half>
        {isPeerVisibilityEnabled && (
          <div style={{paddingBottom: '14px'}}>
            <PeerVisibilityCheckbox initialValue={step?.allowPeerVisibility} />
          </div>
        )}
        <SubmissionType
          disabled={step && step.isSubmissionTypeEditable === false}
          initialValue={step?.submissionType}
        />
      </Grid.Box>

      <Attachments {...attachmentProps} />
    </Grid.Container>
  );
};

export default ProjectForm;
